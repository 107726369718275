import React from 'react'
import { Link } from 'gatsby'
import TextLayout from '@layouts/text'

const NotFoundPage: React.FC = () => {
  return (
    <TextLayout title='Page Not Found'>
      <h2>Oops!</h2>
      <p>
        You can start over by visiting the <Link to='/'>homepage</Link>.
      </p>
    </TextLayout>
  )
}

export default NotFoundPage
